import React from 'react'

import { Hero } from '../../components/blog/hero'
import { PostSidebar } from '../../components/blog/post-sidebar'
import { Breadcrumbs, BreadcrumbsItem } from '../../components/breadcrumbs'
import { Container } from '../../components/container'
import SEO from '../../components/seo'
import img1 from './images/co-je-to-jojo-efekt-a-jak-s-nim-zatocit.jpg'
import styles from './post-grid.module.css'

const CoJeToJojoEfektAJakSNimZatocit = () => {
  return (
    <div className={styles.wrapper}>
      <SEO
        title={'Co je to jojo efekt a jak s ním zatočit | NutritionPro'}
        description={
          'Sacharidy jsou jednou ze tří hlavních živin. Množství energie v 1 gramu jsou 4 kcal (17 kJ). Slouží především jako zdroj energie. Některé naše orgány jsou schopny jako zdroj energie využít pouze glukózu (monosacharid). Sacharidy slouží také jako zásobárna energie a jsou důležité pro zdraví našich střev. Měly by tvořit více než 50 % našeho jídelníčku. Je důležité rozlišovat kvalitu a vlastnosti sacharidů. Můžeme je dělit na jednoduché a komplexní, ale také na mono, oligo a polysacharidy.'
        }
      />
      <Container>
        <Breadcrumbs style={{ margin: '24px 0' }}>
          <BreadcrumbsItem link="/">Domu</BreadcrumbsItem>
          <BreadcrumbsItem link="/blog/posts">Blog</BreadcrumbsItem>
          <BreadcrumbsItem link="/blog/co-je-to-jojo-efekt-a-jak-s-nim-zatocit">
            Co je to jojo efekt a jak s ním zatočit
          </BreadcrumbsItem>
        </Breadcrumbs>
      </Container>
      <Container>
        <div className={styles.postGrid}>
          <div>
            <Hero
              title="Co je to jojo efekt a jak s ním zatočit"
              date="09.04.2023"
            />
            <div>
              <h5 className={styles.postTitle}>Co je to jojo efekt?</h5>
              <p className={styles.postText}>
                Jojo efekt je termín, který se používá k popisu situace, kdy se
                člověk snaží trvale zhubnout, pokud se mu však povede váhu
                snížit, za nějaký čas se <b>začne vracet</b> na prvotní hodnoty
                a někdy i <b>přesáhne původní váhu.</b> Tento efekt je způsoben
                dodržováním drastických diet nebo nesprávně nastavených dietních
                plánů, které danému člověku nedovolují udržet si vytouženou váhu
                po delší dobu, a zároveň ho nenaučí nic o zdravém životním
                stylu. <b>Hubnutí</b> v tomto případě bývá až{' '}
                <b>příliš rychlé</b> a obvykle se, místo chtěného tuku, vytrácí
                hlavně voda a svalová hmota.
              </p>

              <img
                src={img1}
                className={styles.postImg}
                style={{ marginBottom: '40px' }}
                alt="duveshka držící váhy"
              />

              <h5 className={styles.postTitle}>Příčiny jojo efektu</h5>
              <p className={styles.postText}>
                Jak už jsme si řekli, nejčastější příčinou bývá{' '}
                <b>špatně nastavený jídelníček</b>. Jojo efekt může způsobit
                například <b>nevhodná dieta,</b> která vám radí vynechat určitou
                skupinu živin, jako jsou třeba sacharidy či tuky. Můžete mít
                také špatně nastavený příjem a <b>jíst málo.</b> Nebo si{' '}
                <b>zakazujete jíst úplně vše, co máte rádi.</b> Všechny tyto
                restrikce mohou způsobit jojo efekt. Pokud je váš jídelníček
                nastaven <b>neudržitelně</b>, vrátíte se dřív nebo později k{' '}
                <b>vašim starým zvykům</b> a váha v tu chvíli <b>začne růst</b>.{' '}
                Je možné, že vás diety natolik rozhází, že se začnete stravovat
                ještě hůř a dostanete se dokonce na vyšší váhu, než původně.
              </p>

              <p className={styles.postText}>
                Pokud budete hladovět, následně zhubnete a začnete jíst opět
                normálně, tělo se bude radovat, že má zase{' '}
                <b>normální příjem energie a živin</b> a začne si je{' '}
                <b>ukládat</b> s obavami, že ho zase začnete trápit hladem.{' '}
                <b>
                  Metabolismus se zpomalí, sníží se hodnota bazálního
                  metabolismu
                </b>{' '}
                a tělo si začne energii ukládat na horší časy v podobě{' '}
                <b>tukových zásob</b>. Tedy znovu přiberete. A jojo efekt je na
                světě.
              </p>

              <p className={styles.postText}>
                <b>TIP:</b> Dostaňte se do formy díky{' '}
                <a
                  href="https://nutritionpro.cz/blog/dostante-se-do-formy/"
                  target="_blank"
                >
                  zdravému stravování.
                </a>
              </p>

              <h5 className={styles.postTitle}>Následky jojo efektu</h5>
              <p className={styles.postText}>
                Neustále honění se za ideální postavou a následné selhání může
                způsobit <b>pocit zklamání a nedostatečnosti.</b> Dodržování
                drastických diet a hladovění může vést ke stavům{' '}
                <b>vyčerpání</b> a <b>špatné nálady.</b> Nedostatek živin může
                způsobit vypadávání vlasů, problémy s kůží, různé záněty či
                problémy s trávením a mnoho dalšího.
              </p>

              <p className={styles.postText}>
                Následkem je také <b>neustále se měnící váha,</b> která může ve
                výsledku skončit u mnohem vyššího čísla, než původně. Celý tento
                proces také vede k <b>rozházenému metabolismu i psychice.</b>
              </p>

              <h5 className={styles.postTitle}>
                Jak předejít jojo efektu (nebo se z něj dostat ven)
              </h5>
              <p className={styles.postText}>
                “K úspěchu nejede výtah. Musíte jít po schodech.”{' '}
                <b>Nesnažte se hledat zkratky</b>, cestu si akorát prodloužíte.
                Místo nesmyslných diet se raději začněte{' '}
                <b>vzdělávat v oblasti výživy.</b> Naučte se stravovat tak,
                abyste mohli{' '}
                <b>pokračovat i po dosažení optimální hmotnosti.</b> Jezte
                dostatek celorznného pečiva, ovoce, zeleniny, mléčných výrobků,
                luštěnin, libového masa, nebojte se příloh a zdravých tuků a
                jednou za čas si dejte i něco dobrého. Zjistěte si, kolik byste
                toho měli za den sníst a <b>dodržujte to</b>. Pro hubnutí by
                tělo mělo být v <b>lehkém kalorickém deficitu</b>, ale nemělo by
                strádat.
              </p>

              <p className={styles.postText}>
                <b>TIP:</b> Pokud chcete mít jistotu, že váš jídelníček obsahuje
                dostatek živin a zároveň jste v lehkém kalorickém deficitu,
                zkuste náš{' '}
                <a href="https://nutritionpro.cz/" target="_blank">
                  hubnoucí program s krabičkami.
                </a>
              </p>

              <p className={styles.postText}>
                Také si stanovte <b>reálný cíl</b>. Zdravé hubnutí znamená
                shodit 0,5 - 1,5 kg týdně, což může být sice méně, než na
                radikální dietě, ale jedná se o kilogramy, které s největší
                pravděpodobností už nenaberete zpět a bude se{' '}
                <b>skutečně jednat o tuk</b>, nikoliv o svaly a vodu.{' '}
                <b>Nezapomínejte ani na pohyb</b>, který je při hubnutí důležitý
                a může vám dost pomoci. Vybírejte takový, který vás bude bavit,
                jedině tak pro vás bude udržitelný.
              </p>

              <p className={styles.postText}>
                Autorka: Ing. Kristina Heřmanová
              </p>
            </div>
          </div>
          <PostSidebar />
        </div>
      </Container>
    </div>
  )
}

export default CoJeToJojoEfektAJakSNimZatocit
